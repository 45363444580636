<template>
  <div
    v-if="props.modelValue"
    class="fixed left-0 top-0 z-[60] flex h-full w-full items-center justify-center"
  >
    <div
      class="max-h-full w-full overflow-auto text-center md:p-6 md:py-[100px]"
    >
      <div
        class="absolute left-0 top-0 z-10 h-full w-full bg-black/[.6]"
        @click="$emit('update:modelValue', false)"
      />
      <div class="relative z-20 inline-block max-w-full text-left">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: [Boolean, String],
})
defineEmits(['update:modelValue'])

onMounted(() => {
  document.querySelector('body').style.overflow = 'hidden'
})
onUnmounted(() => {
  document.querySelector('body').style.overflow = 'auto'
})
</script>
